import React, {useState} from 'react';
import {ApolloProvider} from "@apollo/client";
import SingleOnboard from "src/pages/onboard/single-onboard";
import { getApolloClient } from "src/clients/apollo-client";
import {
    Route,
    Switch,
    BrowserRouter
} from 'react-router-dom';
import {PrincipalList} from "src/pages/principals/principal-list";
import DefaultErrorPage from "src/pages/default-error-page";
import {AppWrapper} from "@amzn/alchemy-components-react";
import {useTranslation} from "react-i18next";
import { PageType, TenantID } from "src/common/enums";
import * as CONSTANTS from "src/common/constants";
import {LanguageDropdown} from "src/components/language-dropdown";
import {
    MfaRegistration
} from "src/pages/mfaRegistration/mfa-registration";
import { PrintOtp } from "src/pages/principals/print-otp";
import Onboard from "src/pages/onboard/onboard";
import BulkOnboard from "src/pages/onboard/bulk-onboard";
import {BaselinePrincipals} from "src/pages/baseline/principals/baseline-principals";
import {BaselinePermissions} from "src/pages/baseline/permissions/baseline-permissions";
import {AuthProvider, AuthRequiredRoute} from "src/components/auth/auth-provider";
import {AuthPage} from "src/pages/auth-page";
import { WorkflowList } from "src/pages/workflows/workflow-list";
import { SignoutButton } from "src/components/signout-button";
import { TokenManager } from "@amzn/fitjava-script-client";
import {BaselinePermissionsV1} from "src/pages/baseline/permissions/baseline-permissions-v1";

type NavProp = {
    label: string,
    url: string,
    active: boolean
}

export const App = () =>  {
    const {t} = useTranslation();
    const [sideNav, setSideNav] = useState<NavProp[]>([]);
    const [header, setHeader] = useState(CONSTANTS.AFTX_APP_HEADER);

    const setActivePage = (activePage: string) => {
        if (activePage === PageType.MFA_REGISTRATION) {
            // Public Endpoints
            setSideNav([
                {
                    label: t('register-mfa'),
                    url: CONSTANTS.MFA_REGISTRATION_URL,
                    active: PageType.MFA_REGISTRATION === activePage
                }
            ]);
        } else if (activePage === PageType.BASELINE_PERMISSION) {
            // Sidebar is confusing for baselining permissions because none of the options are valid currently
            setSideNav([]);

            // SOX reviewers are special
            setHeader(CONSTANTS.SOX_APP_HEADER);

        } else {
            // Authenticated Endpoints
            setSideNav([
                {
                    label: t('onboard'),
                    url: CONSTANTS.ONBOARD_URL,
                    active: PageType.ONBOARD === activePage,
                },
                {
                    label: t('users'),
                    url: CONSTANTS.PRINCIPAL_LIST_URL,
                    active: PageType.PRINCIPAL_LIST === activePage,
                },
                {
                    label: t('workflows'),
                    url: CONSTANTS.WORKFLOW_LIST_URL,
                    active: PageType.WORKFLOW_LIST == activePage
                },
            ]);
        }
    }

    return (
        <ApolloProvider client={getApolloClient()}>
            <AuthProvider>
                <AppWrapper
                    id="fmui-appwrapper"
                    className="min-vh-100"
                    headerLink={"/"}
                    header={header}
                    sideNav={sideNav}
                >
                    <BrowserRouter>
                        <Switch>
                            // Authentication Route
                            <Route exact path="/auth*">
                                <AuthPage/>
                            </Route>

                            // Private Routes
                            <AuthRequiredRoute exact path={["/", "/onboard/"]}>
                                <Onboard setActivePage={setActivePage}/>
                            </AuthRequiredRoute>
                            <AuthRequiredRoute exact path={"/onboard/single"}>
                                <SingleOnboard setActivePage={setActivePage}/>
                            </AuthRequiredRoute>
                            <AuthRequiredRoute exact path={"/onboard/bulk"}>
                                <BulkOnboard setActivePage={setActivePage}/>
                            </AuthRequiredRoute>
                            <AuthRequiredRoute exact path="/principal">
                                <PrincipalList setActivePage={setActivePage}/>
                            </AuthRequiredRoute>
                            <AuthRequiredRoute exact path="/principal/print-otp">
                                <PrintOtp setActivePage={setActivePage}/>
                            </AuthRequiredRoute>
                            <AuthRequiredRoute exact path="/principal/baseline/*">
                                <BaselinePrincipals setActivePage={setActivePage}/>
                            </AuthRequiredRoute>
                            <AuthRequiredRoute exact path="/permission/baseline/*">
                                <BaselinePermissions setActivePage={setActivePage}/>
                            </AuthRequiredRoute>
                            <AuthRequiredRoute exact path="/workflow">
                                <WorkflowList setActivePage={setActivePage}/>
                            </AuthRequiredRoute>

                            // Public Routes
                            <Route exact path="/mfa-registration*">
                                <MfaRegistration tenantId={TenantID.AFTX} setActivePage={setActivePage}/>
                            </Route>
                            <Route exact path="/3pl/mfa-registration*">
                                <MfaRegistration tenantId={TenantID.AFTX} setActivePage={setActivePage}/>
                            </Route>
                            <Route exact path="/scc/mfa-registration*">
                                <MfaRegistration tenantId={TenantID.SCC} setActivePage={setActivePage}/>
                            </Route>
                            <Route path="*">
                                <DefaultErrorPage setActivePage={setActivePage}/>
                            </Route>

                        </Switch>
                    </BrowserRouter>
                    <div slot="topbar-actions" className="b-action-background d-none d-md-flex align-items-center">
                        <LanguageDropdown />
                        {TokenManager.tokensExist() &&
                            <SignoutButton />
                        }
                    </div>
                </AppWrapper>
            </AuthProvider>
        </ApolloProvider>
    );
}

export default App;
