import {BaseliningPermissionOperation, BaseliningStatus} from "src/common/enums";
export interface BaselinePermissionsEmployeeData {
    employeeId: string,
    firstName: string,
    lastName: string,
    alias: string,
    currentPermission: BaseliningPermissionOperation,
    proposedPermission?: BaseliningPermissionOperation,
    baseliningStatus?: BaseliningStatus,
    managerAlias?: string,
    delegationManagerAlias?: string,
}

export interface EmployeeData {
    employeeId: string,
    employeeLogin: string,
    employeeName: string,
    tupacPermissionLevelBefore: string,
    baseliningStatus: string,
    managerLogin: string,
    delegationManagerLogin?: string,
    managerName?: string,
    employeeLoginManagerLogin?: string,
    tenantScopeIndex?: string,
    ttl?: string,
}

export const parseEmployeeToBaselinePermissionsEmployeeData = (employeeData: EmployeeData): BaselinePermissionsEmployeeData => {
    // Split employee name into first and last name. Handles middle names by joining remaining parts.
    const nameParts = employeeData.employeeName.split(' ');
    const firstName = nameParts[0];
    const lastName = nameParts.slice(1).join(' ');

    return {
        employeeId: employeeData.employeeId,
        firstName: firstName,
        lastName: lastName,
        alias: employeeData.employeeLogin,
        currentPermission: employeeData.tupacPermissionLevelBefore as BaseliningPermissionOperation,
        baseliningStatus: employeeData.baseliningStatus as BaseliningStatus,
        managerAlias: employeeData.managerLogin,
        delegationManagerAlias: employeeData.delegationManagerLogin,
    };
};

export const parseEmployeesToBaselineData = (employeesData: EmployeeData[]): BaselinePermissionsEmployeeData[] => {
    return employeesData.map(employeeData => parseEmployeeToBaselinePermissionsEmployeeData(employeeData));
};
