import React, { useEffect, useState } from "react";
import { PageProps } from "src/pages/page-interface";
import { useLocation } from "react-router-dom";
import {Button, Card, Checkbox, Icon, Input, Modal, RadioButtons, Tooltip} from '@amzn/alchemy-components-react'
import {
    AlertType,
    BaseliningPermissionOperation,
    BaseliningStatus,
    PageType,
    PermissionBaseliningSignalType,
    TenantID,
    WorkflowType
} from "src/common/enums";
import {
    BaselinePermissionsEmployeeData,
    EmployeeData,
    parseEmployeesToBaselineData
} from "src/models/baseline-permissions-employee-data";
import { logger } from "src/logger";
import {useLazyQuery, useMutation} from "@apollo/client";
import { GetActiveWorkflowStateData, GetActiveWorkflowStateVariables } from "src/models/get-active-workflow-state";
import { GET_ACTIVE_WORKFLOW_STATE_QUERY, SIGNAL_WORKFLOW_MUTATION } from "src/common/gql-operations";
import {
    ACCESS_DENIED,
    BASELINING_STATUS,
    CALLABLE_EXCEPTION,
    CONFIRM,
    DELEGATION_STEP,
    EMPTY_VALUE,
    FAQ_URL,
    INPUT,
    NETWORK_ONLY,
    NO_ACTIVE_WORKFLOW_FOUND,
    PERMISSION_EMPLOYEE_DATA,
    PERMISSION_NAME,
    PERMISSION_TO_ABBREVIATION_MAP,
    PERMISSIONS,
} from "src/common/constants";
import { SignalWorkflowVariables } from "src/models/signal-workflow";
import { useTranslation } from "react-i18next";
import { addDarkModeListener, generateBaseliningPermissionWorkflowArn, isDarkMode } from "src/common/util";
import DefaultErrorPage from "src/pages/default-error-page";
import { AlertBar } from "src/components/alert-bar";
import { FallbackSpinner } from "src/components/fallback-spinner";
import * as KatalMetrics from "@amzn/katal-metrics";
import initialMetricsPublisher from "src/metrics";
import {TokenUtil} from "src/common/token-util";
import {ActionsDropdown} from "src/components/actions-dropdown";
const cloudWatchDimensions = [
    new KatalMetrics.Metric.String('page', 'baseline-permissions'),
]
const additionalMetricsContext = new KatalMetrics.Context({cloudWatchDimensions});
const baselineMetricPublisher = initialMetricsPublisher.newChildActionPublisherForMethod('ALL', additionalMetricsContext);
const BASELINE_PERMISSION_PAGE_PATH_PREFIX = '/permission/baseline/'

/**
 * Component to create Baseline permissions Page.
 */
export const BaselinePermissions = (props: PageProps) => {
    const { t } = useTranslation();
    const location = useLocation();
    const [isValidPath, setIsValidPath] = useState<boolean>(false);
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [siteId, setSitedId] = useState<string>();
    const [permissionId, setPermissionId] = useState<string>();
    const [permissionName, setPermissionName] = useState<string>();
    const [isBaseliningBatchSubmitted, setIsBaseliningBatchSubmitted] = useState<boolean>(false);
    // Maps from manager / delegator to set of employee entries
    const [permissionBaselineReviewerMap, setPermissionBaselineReviewerMap] =
        useState<Map<string, BaselinePermissionsEmployeeData[]>>(new Map());
    // Maps from employee entries to selected permission level
    const [permissionBaselineSelectionMap, setPermissionBaselineSelectionMap] =
        useState<Map<BaselinePermissionsEmployeeData, BaseliningPermissionOperation>>(new Map());

    const [selectedItemsTable, setSelectedItemsTable] = useState<BaselinePermissionsEmployeeData[]>([]);
    const loginAlias = TokenUtil.getAlias() == null ? '' : TokenUtil.getAlias()!;
    // TODO SIM: https://sim.amazon.com/issues/AFTI-3250 read the viewAsAlias value from the url query string.
    const [viewAsAlias, setViewAsAlias] = useState<string>(loginAlias);
    const [viewAsAliasInput, setViewAsAliasInput] = useState<string>(EMPTY_VALUE);
    const [viewAsModalOpen, setViewAsModalOpen] = useState<boolean>(false);
    const [isAcknowledged, setIsAcknowledged] = useState(false);
    const [alertMessage, setAlertMessage] = useState<string>();
    const [delegationModalOpen, setDelegationModalOpen] = useState<boolean>(false);
    const [delegationStep, setDelegationStep] = useState<DELEGATION_STEP>(INPUT);
    const [delegateAlias, setDelegateAlias] = useState<string>(EMPTY_VALUE);
    const columnWidths = {
        checkbox: '8%',
        site: '5%',
        permission: '10%',
        employeeId: '10%',
        alias: '10%',
        firstName: '10%',
        lastName: '10%',
        level: '37%'
    };
    const [alertBarType, setAlertBarType] = useState<AlertType>();
    const [alertBarHeader, setAlertBarHeader] = useState<string>();
    const [alertBarMsg, setAlertBarMsg] = useState<string>();
    const [dismissible, setDismissible] = useState<boolean>();
    const [signalType, setSignalType] = useState<PermissionBaseliningSignalType>();
    const [shouldShowLoadingSpinner, setShouldShowLoadingSpinner] = useState(false);
    const [isNoPendingReview, setIsNoPendingReview] = useState(false)
    const [isDark, setIsDark] = useState(isDarkMode());
    useEffect(() => {
        return addDarkModeListener((isDark) => {
            setIsDark(isDark);
        });
    }, []);

    const [getActiveWorkflowState, getActiveWorkflowStateResults] =
        useLazyQuery<GetActiveWorkflowStateData, GetActiveWorkflowStateVariables>(GET_ACTIVE_WORKFLOW_STATE_QUERY, {
            fetchPolicy: NETWORK_ONLY,
            onCompleted: data => {
                logger.info(`Retrieved data from getActiveWorkflowState for workflowId ${data.getActiveWorkflowState.workflowDetails.workflowId}`);
                baselineMetricPublisher.publishCounter('get-active-workflow-state.SUCCESS', 1);
                baselineMetricPublisher.publishCounter('get-active-workflow-state.ERROR', 0);
                const workflowDetails = data.getActiveWorkflowState.workflowDetails;
                const workflowId = workflowDetails.workflowId;
                const workflowStateData = workflowDetails.workflowStateData
                logger.info(`Parsing the workflowStateData: ${workflowStateData} for workflowId: ${workflowId}`)
                if (workflowStateData) {
                    // Parse permission name from workflowStateData.
                    const permissionName = JSON.parse(workflowStateData)[PERMISSION_NAME];
                    if (permissionName) {
                        setPermissionName(permissionName)
                    } else {
                        setPermissionName(EMPTY_VALUE)
                        logger.error(`PermissionName not found in the workflowStateData for workflowId: ${workflowId}`)
                    }
                    // Parse employee data from workflowStateData
                    const employeesData: EmployeeData[] = JSON.parse(JSON.parse(workflowStateData)[PERMISSION_EMPLOYEE_DATA]);
                    if (!employeesData || employeesData.length === 0) {
                        // If no employee data, maps will be reset
                        logger.info(`EmployeesData not found in the workflowStateData for workflowId: ${workflowId}`)
                        setPermissionBaselineSelectionMap(new Map())
                        setPermissionBaselineReviewerMap(new Map())
                        setIsNoPendingReview(true)
                    } else {
                        // Set permission reviewer maps -> { manager alias : list of employees }
                        setIsNoPendingReview(false)
                        const baselinePermissionsEmployees = parseEmployeesToBaselineData(employeesData)
                        setPermissionBaselineReviewerMap(getEmployeesDataFromWorkflowStateData(
                            workflowId,
                            baselinePermissionsEmployees
                        ));
                        // Create and set permission selection maps -> { employee : permission level}
                        const permissionsBaselineSelectionMap = new Map<BaselinePermissionsEmployeeData, BaseliningPermissionOperation>();
                        baselinePermissionsEmployees.forEach(baselinePermissionsEmployee => {
                            permissionsBaselineSelectionMap.set(baselinePermissionsEmployee, baselinePermissionsEmployee.currentPermission);
                        });
                        setPermissionBaselineSelectionMap(permissionsBaselineSelectionMap);
                    }
                    setSelectedItemsTable([])
                }
            },
            onError: error => {
                let accessDenied = false;
                error.graphQLErrors.forEach((gqlError) => {
                    logger.info(`gqlError.message = ${gqlError.message}`);
                    if (gqlError.message.includes(NO_ACTIVE_WORKFLOW_FOUND)) {
                        logger.info("No Active baselining permissions workflow found.");
                        baselineMetricPublisher.publishCounter('get-active-workflow-state.ActiveWorkflowNotFound', 1);
                        setIsBaseliningBatchSubmitted(true);
                        return;
                    } else if (gqlError.message.includes(ACCESS_DENIED)) {
                        logger.info("Access denied to getActiveWorkflowState.");
                        baselineMetricPublisher.publishCounter('get-active-workflow-state.AccessDenied', 1);
                        accessDenied = true;
                        return;
                    }
                });

                logger.error("Call to getActiveWorkflowState failed!", error);
                baselineMetricPublisher.publishCounter('get-active-workflow-state.ERROR', 1);
                baselineMetricPublisher.publishCounter('get-active-workflow-state.SUCCESS', 0);
                // TODO SIM: https://sim.amazon.com/issues/AFTI-3108 improve the error alert with more informative messages.
                if  (accessDenied) {
                    // The alert is dismissible if the login alias is different from view as alias. This allows user to
                    // acknowledge the error and try entering a different alias without the error message persisting on the screen.
                    const errorMessage = loginAlias !== viewAsAlias ?
                        t('sox-permissions-get-active-workflow-state-access-denied-message-view-as', {viewAsAlias: viewAsAlias})
                        : t('sox-permissions-get-active-workflow-state-access-denied-message-general')
                    showErrorAlert(
                        t('sox-permissions-get-active-workflow-state-access-denied-header-alpha'),
                        errorMessage,
                        loginAlias !== viewAsAlias
                    )
                } else {
                    showErrorAlert(
                        t('failed-to-get-active-baselining-permissions-workflow-status'),
                        error.message,
                        false
                    )
                }
                // Reset the 'View As' alias to the user's login alias. This reset will trigger a
                // GetActiveWorkflowStatus API call and refresh the permission review tables if there's a mismatch
                // between the 'View As' alias and the login alias.
                setViewAsAlias(loginAlias)
            }
        });

    const [signalWorkflow, signalWorkflowResults] = useMutation<any, SignalWorkflowVariables>(SIGNAL_WORKFLOW_MUTATION, {
        onCompleted: () => {
            baselineMetricPublisher.publishCounter('signal-workflow.SUCCESS', 1);
            baselineMetricPublisher.publishCounter('signal-workflow.ERROR', 0);
            const numSelectedPermissions: number = selectedItemsTable.length;
            // Schedule GetActiveBaseliningPermissionsWorkflow in 5 seconds to get page refreshed
            setShouldShowLoadingSpinner(true);
            setTimeout(() => {
                callGetActiveBaseliningPermissionsWorkflow();
                setShouldShowLoadingSpinner(false);
            }, 3000);
            // Publish metrics and show success alert bar
            if (PermissionBaseliningSignalType.DELEGATION === signalType) {
                baselineMetricPublisher.publishCounter('PermissionsDelegated', numSelectedPermissions);
                logger.info(`Successfully sent delegation signal to baselinePermissionsWorkflow for ${numSelectedPermissions} permissions.`);
                showSuccessAlert(t('baseline-permissions-signal-delegate-header'), t('baseline-permissions-signal-delegate-message'));
            } else if (PermissionBaseliningSignalType.SUBMISSION === signalType) {
                baselineMetricPublisher.publishCounter('PermissionsModified', numSelectedPermissions);
                logger.info(`Successfully sent submission signal to baselinePermissionsWorkflow for ${numSelectedPermissions} permissions.`);
                showSuccessAlert(t('baseline-permissions-signal-submit-header'), t('baseline-permissions-signal-submit-message'));
            }
        },
        onError: error => {
            error.graphQLErrors.forEach((gqlError) => {
                if (gqlError.message.includes(CALLABLE_EXCEPTION)) {
                    logger.info(`Signal workflow exception: ${gqlError.message}`)
                    baselineMetricPublisher.publishCounter('signal-workflow.CallableException', 1);
                    return;
                }
            });
            logger.error(`Call to signalWorkflow to submit baselining permissions results failed!`, error);
            baselineMetricPublisher.publishCounter('signal-workflow.ERROR', 1);
            baselineMetricPublisher.publishCounter('signal-workflow.SUCCESS', 0);
            showErrorAlert(
                t('failed-to-signal-baselining-permissions-workflow'),
                error.message,
                false
            )
        }
    });

    const getEmployeesDataFromWorkflowStateData = (
        workflowId: string,
        employeesData?: BaselinePermissionsEmployeeData[]
    ) => {
        const permissionsBaselineReviewerMap = new Map<string, BaselinePermissionsEmployeeData[]>();

        if (!employeesData?.length) {
            logger.info(`No employee data found for workflow ${workflowId}`);
            return permissionsBaselineReviewerMap;
        }

        logger.info(`Processing ${employeesData.length} employee records for workflow ${workflowId}`);

        employeesData.forEach(employee => {
            const managerAlias = employee.baseliningStatus === BaseliningStatus.DELEGATED_DESTINATION
                ? employee.delegationManagerAlias
                : employee.baseliningStatus === BaseliningStatus.PENDING
                    ? employee.managerAlias
                    : null;

            if (managerAlias) {
                const existingEmployees = permissionsBaselineReviewerMap.get(managerAlias) || [];
                permissionsBaselineReviewerMap.set(managerAlias, [...existingEmployees, employee]);
            }
        });

        return permissionsBaselineReviewerMap;
    };


    const callGetActiveBaseliningPermissionsWorkflow = () => {
        if (siteId && permissionId) {
            baselineMetricPublisher.publishCounter('get-active-workflow-state.INVOCATION', 1)
            getActiveWorkflowState({
                variables: {
                    getActiveWorkflowStateInput: {
                        tenantId: TenantID.SOX,
                        principalArn: generateBaseliningPermissionWorkflowArn(siteId, permissionId),
                        workflowType: WorkflowType.BASELINING_PERMISSION,
                        viewAs: viewAsAlias
                    }
                }
            })
        }
    }

    /**
     * Signals a workflow with the specified parameters
     * @param signalType - Type of signal (submission or delegation)
     * @param siteId - Site identifier
     * @param permissionId - Permission identifier
     * @param delegatedAlias - Optional delegate alias for delegation workflows
     */
    const signalBaseliningWorkflow = (
        signalType: PermissionBaseliningSignalType,
        siteId: string,
        permissionId: string,
        delegatedAlias?: string
    ) => {
        // Publish metrics
        baselineMetricPublisher.publishCounter('signal-workflow.INVOCATION', 1);
        baselineMetricPublisher.publishCounter(`signal-workflow.${signalType.toUpperCase()}`, 1);

        // Get signal data strings based on signal type
        const selectedPermissions = signalType === PermissionBaseliningSignalType.SUBMISSION
            ? getSelectedPermissionsForSubmission(selectedItemsTable, permissionBaselineSelectionMap)
            : getSelectedPermissionsForDelegation(selectedItemsTable, viewAsAlias, delegatedAlias!);

        setSignalType(signalType);

        // Signal the workflow
        signalWorkflow({
            variables: {
                signalWorkflowInput: {
                    tenantId: TenantID.SOX,
                    principalArn: generateBaseliningPermissionWorkflowArn(siteId, permissionId),
                    workflowType: WorkflowType.BASELINING_PERMISSION,
                    workflowSignalData: `{\"${BASELINING_STATUS}\": \"${signalType}\",\"${PERMISSIONS}\": \"${selectedPermissions.join("|")}\"}`,
                }
            }
        });
    };

    const setInvalidPageState = () => {
        setIsValidPath(false)
        logger.info(' Invalid baselining url path : ' + location.pathname)
        baselineMetricPublisher.publishCounter('Invalid-Baselining-Permissions-url', 1);
    }

    useEffect(() => {
        if (location.pathname.startsWith(BASELINE_PERMISSION_PAGE_PATH_PREFIX)) {
            setIsValidPath(true)
            const pathname = location.pathname;
            const pathSplits = pathname.split('/');

            if(pathSplits.length < 5) {
                setInvalidPageState();
                return;
            }

            const siteId = pathSplits[4].toUpperCase();

            if (pathSplits.length > 6 || (pathSplits.length == 6 && pathSplits[5].length > 0)
                || siteId.length > 4  || !/^[a-z0-9]+$/i.test(siteId)) {
                setInvalidPageState();
                return;
            }

            const permissionId = pathname.split('/')[3];
            setSitedId(siteId)
            setPermissionId(permissionId)
            baselineMetricPublisher.publishCounter(`${permissionId}-BASELINING-PERMISSION`, 1);
            baselineMetricPublisher.publishCounter(`${siteId}-BASELINING-PERMISSION`, 1);
            baselineMetricPublisher.publishCounter(`${siteId}-${permissionId}-BASELINING-PERMISSION`, 1);

            // baselining permissions has its own set of sidebar options
            props.setActivePage(PageType.BASELINE_PERMISSION);
        } else {
            setInvalidPageState();
        }
    }, [])


    useEffect(() => {
        callGetActiveBaseliningPermissionsWorkflow();
    }, [siteId, permissionId, viewAsAlias])

    function generateButtonOptions(employeeData : BaselinePermissionsEmployeeData) {
        const operations : BaseliningPermissionOperation[] = [
            BaseliningPermissionOperation.NONE,
            BaseliningPermissionOperation.BEGINNER,
            BaseliningPermissionOperation.INTERMEDIATE,
            BaseliningPermissionOperation.EXPERT,
            BaseliningPermissionOperation.ADMIN
        ]
        let buttons = [];
        const maximumOperation : BaseliningPermissionOperation = employeeData.currentPermission;
        let greaterThanFinalOperation : boolean = false;
        for(let i = 0; i < operations.length; i++) {
            const operation : BaseliningPermissionOperation = operations[i];
            buttons.push({
                value: operation,
                label: `${t(operation.toLowerCase())}`,
                disabled: greaterThanFinalOperation
            });
            // Further buttons should be disabled
            if(operation == maximumOperation) {
                greaterThanFinalOperation = true;
            }
        }
        return buttons;
    }

    const switchModalOpen = () => {
        logger.info(`Setting modal open: ${!modalOpen}`)
        if (!modalOpen) {
            setAlertMessage(undefined)
            setIsAcknowledged(false)
        }
        setModalOpen(!modalOpen);
    }

    const renderBottomButtons = () => {
        return (
            <div className="d-flex justify-content-center align-items-center w-100 my-3 py-2">
                <div className="mr-1">
                    <Button id="submit-baseline-reset-selection"
                            variant="base"
                            label={`${t('reset')}`}
                            onClick={resetSelection}/>
                </div>
                <div className="mr-1">
                    <Button id="submit-baseline-delegate-selection"
                            variant="action"
                            label={`${t('delegate')}`}
                            disabled={selectedItemsTable.length === 0}
                            onClick={switchBaselineDelegationModalOpen}/>
                </div>
                <div>
                    <Button id="submit-baseline-permissions-selection"
                            variant="action"
                            label={`${t('submit')}`}
                            disabled={selectedItemsTable.length === 0 || loginAlias != viewAsAlias }
                            onClick={switchModalOpen}/>
                </div>
            </div>

        )
    }

    const renderNoPendingReviewInformation = () => {
        return (
            <div className="container-lg mx-auto p-4">
                <Card
                    header={t('baseline-permissions-status', {site: siteId})}
                    className="text-center">
                    <div className="b-background p-4">
                        <h4 id="no-pending-permissions-information">
                            {t('baseline-permissions-batch-no-permission-review', {site: siteId})}
                        </h4>
                        {isNoPendingReview && (
                            <div className="alert alert-info p-3 mx-auto">
                                <h5>
                                    {t('baseline-permissions-batch-submitted-view-as')}
                                </h5>
                            </div>
                        )}
                        <h5 className="mb-3">
                            {t('baseline-permissions-contact-us')}
                        </h5>
                        <a
                            href="https://w.amazon.com/bin/view/AFT/Identity/ULM/ToolPermissionAccessReviewFAQ#HContactUs"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-primary text-decoration-none">
                            {t('contact-support')}
                        </a>
                    </div>
                </Card>
            </div>
        )
    }

    const renderReviewTableComponent = (
        title: string,
        tableContentData: BaselinePermissionsEmployeeData[],
        selectedItems: BaselinePermissionsEmployeeData[],
        setSelectedItems: (items: BaselinePermissionsEmployeeData[]) => void,
        permissionBaselineSelectionMap: Map<BaselinePermissionsEmployeeData, BaseliningPermissionOperation>,
        setPermissionBaselineSelectionMap: (map: Map<BaselinePermissionsEmployeeData, BaseliningPermissionOperation>) => void
    ) => {
        const selectedAll = tableContentData.length > 0 && tableContentData.every(item => selectedItems.includes(item));

        const handleSelectAll = () => {
            if (selectedAll) {
                // Deselect all items from this table
                setSelectedItems(selectedItems.filter(item => !tableContentData.includes(item)));
            } else {
                // Select all items from this table
                const newSelectedItems = [...new Set([...selectedItems, ...tableContentData])];
                setSelectedItems(newSelectedItems);
            }
        };

        const handleCheckboxChange = (item: BaselinePermissionsEmployeeData) => {
            // This removes the item from the selectedItem list if the checkbox was selected and add the item if it was not selected.
            const newSelected = selectedItems.includes(item)
                ? selectedItems.filter(selected => selected !== item)
                : [...selectedItems, item];
            setSelectedItems(newSelected);
        };

        return (
            <div>
                <div className="row page-title justify-content-center title mx-0 mb-0-5 mt-n5 py-4">
                    <span> {title} </span>
                </div>
                <div className="row users-table b-background mx-0 mb-0-5 table-responsive" style={{ overflow: 'visible' }}>
                    <table className="bordered alchemy-table">
                        <thead>
                        <tr key='tableHeader'>
                            <th style={{ width: columnWidths.checkbox }}>
                                <Checkbox
                                    className="mt-1 mb-1"
                                    size="md"
                                    id={`checkbox-select-all`}
                                    label={`${t('select-all')}`}
                                    checked={selectedAll}
                                    onChange={handleSelectAll}
                                />
                            </th>
                            <th style={{ width: columnWidths.site }}>{t('site')}</th>
                            <th style={{ width: columnWidths.permission }}>{t('baseline-permission')}</th>
                            <th style={{ width: columnWidths.employeeId }}>{t('employee-id')}</th>
                            <th style={{ width: columnWidths.alias }}>{t('alias')}</th>
                            <th style={{ width: columnWidths.firstName }}>{t('first-name')}</th>
                            <th style={{ width: columnWidths.lastName }}>{t('last-name')}</th>
                            <th style={{ width: columnWidths.level }}>
                                <div>
                                    {t('baseline-permissions-level')}
                                    <Tooltip id={`baseline-permissions-level-tooltip`}
                                             position="top">
                                    <span slot="trigger">
                                        <Icon icon="warning"
                                              size="sm"
                                              id={'tooltip-icon'}
                                              className="ml-1"/>
                                    </span>
                                        <div className="tooltip-content">
                                            {t('baseline-permission-level-info')} <a
                                            href={FAQ_URL}
                                            className="text-primary text-decoration-none"
                                            target="_blank"
                                            rel="noopener noreferrer">
                                            {t('faqs')}
                                            </a>
                                        </div>
                                    </Tooltip>
                                </div>
                            </th>
                        </tr>
                        </thead>

                        <tbody>
                        {tableContentData.map((tableContentData: BaselinePermissionsEmployeeData, index: number) => {
                            return (<tr key={`key${index}`}>
                                <td>
                                    <Checkbox
                                        className="pl-2"
                                        id={`checkbox-${tableContentData.employeeId}`}
                                        size="md"
                                        checked={selectedItems.includes(tableContentData)}
                                        onChange={() => handleCheckboxChange(tableContentData)}
                                    />
                                </td>
                                <td>{siteId}</td>
                                <td>{`${permissionName} (${permissionId})`}</td>
                                <td>{tableContentData.employeeId}</td>
                                <td>{tableContentData.alias}</td>
                                <td>{tableContentData.firstName}</td>
                                <td>{tableContentData.lastName}</td>
                                <td style={{
                                        backgroundColor: isDark ? '#3A3A3A' : '#DFDFDF',
                                        color: isDark ? '#FFFFFF' : '#000000'}}>
                                    <RadioButtons id={`radio-btn-${tableContentData.employeeId}`}
                                                  variant='horizontal'
                                                  buttons={generateButtonOptions(tableContentData)}
                                        // The state of option selection for radio button is manged by alchemy component hence setting here default which doesn't change value when re-renders
                                                  value={permissionBaselineSelectionMap.get(tableContentData) || tableContentData.currentPermission}
                                                  onChange={
                                                      (e) => {
                                                          setPermissionBaselineSelectionMap(new Map(permissionBaselineSelectionMap).set(tableContentData, e.target.value));
                                                      }
                                                  }
                                    />
                                </td>
                            </tr>)
                        })}
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }

    const renderPageHeader = () => {
        if (isValidPath) {
            return (
                <div className="d-flex justify-content-between b-background align-items-center w-100 my-3 py-2"
                     id="baseline-permission-page-header">
                    <div className="p-sm-2">
                        <ActionsDropdown onViewAsClick={switchBaselineViewAsModalOpen}/>
                    </div>
                    <div className="d-flex flex-column mr-5">
                        <span>
                          {t('logged-in-as', {managerName: `${loginAlias}`})}
                        </span>
                        <span>
                          {t('view-as', {managerName: `${viewAsAlias}`})}
                        </span>
                    </div>
                </div>
            )
        }
    }

    const renderPageSupport = () => {
        if (isValidPath) {
            return (
                <div className="footer-support border-top bg-light">
                    <div className="container py-3">
                        <div className="text-center" id="need-help-footer">
                            {t('need-help-faq')} <a
                            href={FAQ_URL}
                            className="text-primary text-decoration-none"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {t('faqs')}
                        </a>
                        </div>
                    </div>
                </div>
            );
        }
    };

    /**
     * Shows an error via the AlertBar.
     *
     * @param header the header for the AlertBar.
     * @param msg the message for the AlertBar.
     * @param dismissible if the AlertBar is dismissible
     */
    const showErrorAlert = (header: string, msg: string, dismissible: boolean) => {
        let errorMessage = msg
        if (errorMessage.includes(CALLABLE_EXCEPTION)) {
            errorMessage = msg.split(CALLABLE_EXCEPTION)[1];
        }
        setAlertBarType(AlertType.error);
        setAlertBarHeader(header)
        setAlertBarMsg(errorMessage);
        setDismissible(dismissible);
    }

    /**
     * Shows a success via the AlertBar.
     *
     * @param header the header for the AlertBar.
     * @param msg the message for the AlertBar.
     */
    const showSuccessAlert = (header: string, msg: string) => {
        setAlertBarType(AlertType.success);
        setAlertBarHeader(header)
        setAlertBarMsg(msg);
    }

    /**
     * Resets the AlertBar since it's re-usable.
     */
    const resetAlertBar = () => {
        setAlertBarType(undefined);
        setAlertBarHeader(undefined);
        setAlertBarMsg(undefined);
    }

    const renderAlertBar = () => {
        if (alertBarType !== undefined) {
            return (
                <AlertBar
                    id="baseline-permissions-alert-bar"
                    result={alertBarType}
                    dismissible={dismissible}
                    header={alertBarHeader!}
                    message={alertBarMsg!}
                    reset={resetAlertBar}
                />
            );
        }
        return null;
    }

    const handleSubmissionConfirm = () => {
        if (!isAcknowledged) {
            setAlertMessage(t('submission-confirmation-not-ack'));
            return;
        }
        setModalOpen(false);
        signalBaseliningWorkflow(PermissionBaseliningSignalType.SUBMISSION, siteId!, permissionId!);
    };

    const renderBaselineConfirmationModal = () => {
        const isConfirmDisabled = selectedItemsTable.length === 0 || loginAlias != viewAsAlias;
        return (
            <div className="row">
                <Modal id="baseline-permissions-modal"
                       className="mb-1"
                       header={t('baseline-permissions-modal-header')}
                       open={modalOpen}
                       onClose={() => {
                           setModalOpen(false);
                       }}>
                    <div className="row mx-0 mb-0-5 justify-content-center">
                        <p id="baseline-permissions-modal-content">{t('baseline-submission-summary')}</p>
                    </div>
                    <div>
                        <div className="row users-table b-background mx-0 mb-0-5 table-responsive">
                            <table className="compact bordered alchemy-table ">
                                <thead>
                                <tr>
                                    <th>{t('name')}</th>
                                    <th>{t('baseline-permission')}</th>
                                    <th>{t('before')}</th>
                                    <th>{t('after')}</th>
                                </tr>
                                </thead>
                                <tbody>
                                {selectedItemsTable.map((tableContentData: BaselinePermissionsEmployeeData) => {
                                    const selection = permissionBaselineSelectionMap.get(tableContentData)
                                    return (
                                        <tr style={{backgroundColor: selection != tableContentData.currentPermission
                                                ? (isDark ? '#665c30' : '#fff8e1')
                                                : '',
                                                color: isDark ? '#FFFFFF' : '#000000'}}>
                                            <td>{`${tableContentData.firstName} ${tableContentData.lastName}`}</td>
                                            <td>{`${permissionName} (${permissionId})`}</td>
                                            <td>{tableContentData.currentPermission}</td>
                                            <td>{selection === undefined ? tableContentData.currentPermission : selection}</td>
                                        </tr>)
                                })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="container-fluid">
                        <div className="row mx-0 mb-0-5 justify-content-center">
                            <p>{t('baseline-permission-submission-confirmation')}</p>
                            <span>
                                <Checkbox
                                    id='submit-acknowledge-checkbox'
                                    className="pl-2"
                                    size="md"
                                    checked={isAcknowledged}
                                    onChange={(e) => setIsAcknowledged(e.target.checked)}/>
                                {t('i-acknowledge')}
                            </span>
                        </div>
                        <div className='row mx-0 mb-0-5 justify-content-center'>
                            <p id='alert-confirmation-model' style={{ color: 'red' }}
                               className="alert-message">{alertMessage}</p>
                        </div>
                        <div className="row mx-0 mb-0-5 d-flex justify-content-center">
                            <Button className='mr-1'
                                    variant="base"
                                    id='baseline-permissions-modal-cancel-btn'
                                    onClick={switchModalOpen}>
                                {t('cancel')}
                            </Button>
                            <Button className='confirm-button'
                                    variant="action"
                                    id='baseline-permissions-modal-confirm-btn'
                                    onClick={handleSubmissionConfirm}
                                    disabled={isConfirmDisabled}>
                                {t('confirm')}
                            </Button>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }

    function resetSelection() {
        // Reset selected items (checkboxes)
        setSelectedItemsTable([]);

        // Reset radio buttons to original values (current permissions)
        if (permissionBaselineReviewerMap.size > 0) {
            const resetSelectionMap = new Map<BaselinePermissionsEmployeeData, BaseliningPermissionOperation>();

            // Iterate through all manager's employee data
            Array.from(permissionBaselineReviewerMap.values()).forEach(employeeDataArray => {
                employeeDataArray.forEach(employeeData => {
                    // Reset each employee's selection to their current permission
                    resetSelectionMap.set(employeeData, employeeData.currentPermission);
                });
            });

            setPermissionBaselineSelectionMap(resetSelectionMap);
        }
    }

    const isValidAlias = (alias: string): boolean => {
        // Alias should be 3-8 alphabets
        return /^[a-zA-Z]{3,8}$/.test(alias);
    };

    const renderBaselineDelegationModal = () => {
        const isConfirmDisabled = selectedItemsTable.length === 0;

        // handle input box for alais
        const handleAliasInput = (alias: string) => {
            setDelegateAlias(alias);
        };

        // handle next button
        const handleNext = () => {
            if (delegateAlias === EMPTY_VALUE || !isValidAlias(delegateAlias)) {
                return;
            }
            setDelegationStep(CONFIRM);
        };

        // Handle back button
        const handleBack = () => {
            setDelegationStep(INPUT);
            setDelegateAlias(EMPTY_VALUE);
        };

        // Handle cancel button
        const handleCancel = () => {
            // Reset everything when closing the delegation modal
            setDelegationStep(INPUT);
            setDelegateAlias(EMPTY_VALUE);
            setDelegationModalOpen(false);
        };

        const handleDelegationConfirm = (delegateAlias: string) => {
            try {
                // Reset the modal state
                setDelegationModalOpen(false);
                signalBaseliningWorkflow(PermissionBaseliningSignalType.DELEGATION, siteId!, permissionId!, delegateAlias!)
                setDelegationStep(INPUT);
                setDelegateAlias(EMPTY_VALUE);
            } catch (error) {
                // Handle any errors
                console.error('Delegation confirmation failed:', error);
            }
        };

        return (
            <div className="row">
                <Modal id="baseline-permissions-delegation-modal"
                       header={t('delegation')}
                       open={delegationModalOpen}
                       onClose={handleCancel}>
                    {delegationStep === INPUT ? (
                        <div className="ml-5 mr-5 mb-2 mt-2">
                            <div className="align-items-center mb-4">
                                <p>{t('baseline-permission-delegation-question')}</p>
                                <Input
                                    id='delegation-alais-input'
                                    placeholder={t('alias')}
                                    value={delegateAlias}
                                    onInput={e => handleAliasInput(e.target.value)}
                                ></Input>
                            </div>
                            <div className="d-flex justify-content-end">
                                <Button
                                    id='delegation-cancel-btn'
                                    variant="base"
                                    className='mr-1'
                                    onClick={handleCancel}>
                                    {t('cancel')}
                                </Button>
                                <Button
                                    id='delegation-next-btn'
                                    variant="action"
                                    onClick={handleNext}
                                    disabled={!isValidAlias(delegateAlias)}>
                                    {t('next')}
                                </Button>
                            </div>
                        </div>
                    ) : (
                        <div>
                            <div className="d-flex flex-column align-items-start">
                                <p>{t('baseline-permission-delegation-confirmation', {manager2: delegateAlias, manager1: viewAsAlias})}</p>
                                <p>{t('baseline-permission-delegation-confirmation-note')}</p>
                            </div>
                            <div className="row users-table b-background mx-0 mb-0-5 table-responsive">
                                <table id='delegation-table'
                                       className="bordered compact alchemy-table">
                                    <thead>
                                    <tr>
                                        <th>{t('site')}</th>
                                        <th>{t('baseline-permission')}</th>
                                        <th>{t('name')}</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {selectedItemsTable.map((tableContentData: BaselinePermissionsEmployeeData) => {
                                        return (
                                            <tr>
                                                <td>{siteId}</td>
                                                <td>{`${permissionName} (${permissionId})`}</td>
                                                <td>{`${tableContentData.firstName} ${tableContentData.lastName}`}</td>
                                            </tr>)
                                    })}
                                    </tbody>
                                </table>
                            </div>
                            <div className="d-flex justify-content-end">
                                <Button
                                    id='delegation-back-btn'
                                    className='mr-1'
                                    variant="base"
                                    onClick={handleBack}>
                                    {t('back')}
                                </Button>
                                <Button
                                    id='delegation-confirm-btn'
                                    variant="action"
                                    disabled={isConfirmDisabled}
                                    onClick={() => handleDelegationConfirm(delegateAlias)}>
                                    {t('Confirm')}
                                </Button>
                            </div>
                        </div>
                    )}
                </Modal>
            </div>
        );
    }

    const switchBaselineDelegationModalOpen = () => {
        logger.info(`Setting delegation modal open: ${!delegationModalOpen}`)
        if (!delegationModalOpen) {
            setDelegationStep(INPUT);
            setDelegateAlias(EMPTY_VALUE);
        }
        setDelegationModalOpen(!delegationModalOpen);
        return;
    }

    const renderBaselineViewAsModal = () => {
        // handle input box for alais
        const handleAliasInput = (alias: string) => {
            setViewAsAliasInput(alias);
        };

        // handle next button
        const handleNext = () => {
            if (viewAsAliasInput === EMPTY_VALUE) {
                return;
            }
            try {
                // Update the view as alias and reset the input
                setViewAsModalOpen(false);
                setViewAsAlias(viewAsAliasInput);
                setViewAsAliasInput(EMPTY_VALUE);
            } catch (error) {
                // Handle errors
                console.error('View as request failed:', error);
            }
        };

        // Handle cancel button
        const handleCancel = () => {
            // Reset when closing the modal
            setViewAsAliasInput(EMPTY_VALUE);
            setViewAsModalOpen(false);
        };

        return (
            <div className="row">
                <Modal id="baseline-permissions-view-as-modal"
                       header={t('view-as-title')}
                       open={viewAsModalOpen}
                       onClose={handleCancel}>
                        <div className="ml-5 mr-5 mb-2 mt-2">
                            <div className="d-flex flex-column align-items-center justify-content-center mb-4">
                                <p>{t('baseline-permission-view-as-question')}</p>
                                <Input
                                    id='view-as-alais-input'
                                    placeholder={t('alias')}
                                    value={viewAsAliasInput}
                                    onInput={e => handleAliasInput(e.target.value)}
                                ></Input>
                                <p className="mt-2">{t('baseline-permission-view-as-note', {
                                    site: siteId,
                                    permissionName: permissionName,
                                    permissionId: permissionId
                                })}</p>
                            </div>
                            <div className="d-flex justify-content-end">
                                <Button
                                    id='view-as-cancel-btn'
                                    variant="base"
                                    className='mr-1'
                                    onClick={handleCancel}>
                                    {t('cancel')}
                                </Button>
                                <Button
                                    id='view-as-next-btn'
                                    variant="action"
                                    onClick={handleNext}
                                    disabled={!isValidAlias(viewAsAliasInput)}>
                                    {t('next')}
                                </Button>
                            </div>
                        </div>
                </Modal>
            </div>
        );
    }

    const switchBaselineViewAsModalOpen = () => {
        logger.info(`Setting view as modal open: ${!viewAsModalOpen}`)
        if (!viewAsModalOpen) {
            setViewAsAliasInput(EMPTY_VALUE);
        }
        setViewAsModalOpen(!viewAsModalOpen);
        return;
    }

    const renderPageBody = () => {
        if (!isValidPath) {
            return (<DefaultErrorPage setActivePage={props.setActivePage}/>);
        }

        if (isBaseliningBatchSubmitted) {
            // There is no active workflow for the site
            return renderNoPendingReviewInformation()
        } else {
            if (getActiveWorkflowStateResults.error) {
                return renderAlertBar()
            }
            if (getActiveWorkflowStateResults.loading || signalWorkflowResults.loading || shouldShowLoadingSpinner) {
                // Showing loading spinner for getActiveWorkflowStateResults and signalWorkflowResults requests
                return (<FallbackSpinner/>)
            } else {
                const directReportTables: [string, BaselinePermissionsEmployeeData[]][] = [];
                const delegatedTables: [string, BaselinePermissionsEmployeeData[]][] = [];

                Array.from(permissionBaselineReviewerMap.entries()).forEach(entry => {
                    if (entry[0] === viewAsAlias) {
                        directReportTables.push(entry);
                    } else {
                        delegatedTables.push(entry);
                    }
                });
                return (
                    <div>
                        {directReportTables.map(([managerAlias, employeesData], index) => {
                            const title = t('direct-report-of', {managerName: managerAlias});
                            return (
                                <React.Fragment key={`direct-${index}`}>
                                    {renderReviewTableComponent(
                                        title,
                                        employeesData,
                                        selectedItemsTable,
                                        setSelectedItemsTable,
                                        permissionBaselineSelectionMap,
                                        setPermissionBaselineSelectionMap
                                    )}
                                </React.Fragment>
                            );
                        })}

                        {delegatedTables.map(([managerAlias, employeesData], index) => {
                            const title = t('delegated-to', {
                                managerName1: viewAsAlias,
                                managerName2: managerAlias
                            });
                            return (
                                <React.Fragment key={`delegated-${index}`}>
                                    {renderReviewTableComponent(
                                        title,
                                        employeesData,
                                        selectedItemsTable,
                                        setSelectedItemsTable,
                                        permissionBaselineSelectionMap,
                                        setPermissionBaselineSelectionMap
                                    )}
                                </React.Fragment>
                            );
                        })}
                        {!isNoPendingReview && renderBottomButtons()}
                        {renderBaselineConfirmationModal()}
                        {renderBaselineDelegationModal()}
                        {renderBaselineViewAsModal()}
                        {renderAlertBar()}
                        {isNoPendingReview && renderNoPendingReviewInformation()}
                    </div>
                )
            }
        }
    }

    return (
        <div className="container-fluid">
            {renderPageHeader()}
            {renderPageBody()}
            {renderPageSupport()}
        </div>
    )
}

/**
 * Gets selected permissions formatted for submission signal.
 * @param selectedItemsTable
 * @param permissionBaselineSelectionMap
 * @returns Array of permission strings for submission. String format: <employeeLogin>:<fromLevelAbbr>:<toLevelAbbr>
 */
export function getSelectedPermissionsForSubmission(
    selectedItemsTable: BaselinePermissionsEmployeeData[],
    permissionBaselineSelectionMap: Map<BaselinePermissionsEmployeeData, BaseliningPermissionOperation>
) {
    let submissionSelection: string[] = [];
    selectedItemsTable.forEach(selectedItem => {
        const selectedPermissionLevel = permissionBaselineSelectionMap.get(selectedItem);
        if (selectedPermissionLevel) {
            submissionSelection.push(`${selectedItem.alias}:${PERMISSION_TO_ABBREVIATION_MAP.get(selectedItem.currentPermission)}:${PERMISSION_TO_ABBREVIATION_MAP.get(selectedPermissionLevel)}`);
        }
    });
    return submissionSelection;
}

/**
 * Gets selected permissions formatted for delegation signal.
 * @param selectedItemsTable
 * @param viewAsAlias - Alias of the reviewing manager
 * @param delegatedAlias - Alias of the delegate destination
 * @returns Array of permission strings for delegation. String format: <employeeLogin>:<fromManagerLogin>:<toManagerLogin>
 */
export function getSelectedPermissionsForDelegation(
    selectedItemsTable: BaselinePermissionsEmployeeData[],
    viewAsAlias: string,
    delegatedAlias: string
) {
    let delegationSelection: string[] = [];
    selectedItemsTable.forEach(selectedItem => {
        delegationSelection.push(`${selectedItem.alias}:${viewAsAlias}:${delegatedAlias}`);
    });
    return delegationSelection;
}

