export enum TenantID {
    AFTX = '3pl',
    SOX = 'sox',
    SCC = 'scc'
}

export enum AlertType {
    info = 'info',
    error = 'error',
    success = 'success',
    warning = 'warning',
}

export enum PageType {
    ONBOARD = 'ONBOARD',
    PRINCIPAL_LIST = 'PRINCIPAL_LIST',
    BASELINE_PERMISSION = 'BASELINE_PERMISSION',
    WORKFLOW_LIST = 'WORKFLOW_LIST',
    MFA_REGISTRATION = 'MFA_REGISTRATION',
    DEFAULT_ERROR_PAGE = 'DEFAULT_ERROR_PAGE',
}

export enum UserType {
    SYSTEM_ADMIN = 'SYSTEM_ADMIN',
    ABBM_PRIMARY = 'ABBM_PRIMARY',
    ABBM_SECONDARY = 'ABBM_SECONDARY',
    USER_ADMIN_PRIMARY = 'USER_ADMIN_PRIMARY',
    USER_ADMIN_SECONDARY = 'USER_ADMIN_SECONDARY',
    ASSOCIATE = 'ASSOCIATE'
}

export enum AftxRole {
    IB_OB_CLERK = 'IB/OB Clerk',
    IB_OB_TEAM_LEADER = 'IB/OB Team leader',
    ICQA_PROBLEM_SOLVE = 'ICQA / Problem Solve',
    BASE_PROCESS_ASSOCIATE = 'Base Process Associate',
}

export enum GenderType {
    MALE = 'MALE',
    FEMALE = 'FEMALE',
    NOT_DISCLOSED = 'NOT_DISCLOSED',
    UNKNOWN = 'UNKNOWN'
}

export enum BaseliningState {
    NOT_PERFORMED = 'NOT_PERFORMED',
    ALLOWED = 'ALLOWED',
    DENIED = 'DENIED'
}

export enum OtpRetrievalStatus {
    NOT_STARTED,
    IN_PROGRESS,
    COMPLETED,
    FAILED,
}

export enum NotificationMethod {
    EMAIL = 'email',
    PRINT = 'print',
    EMAIL_AND_PRINT = 'email_and_print',  // TODO: Implement when requested by users
}

export enum WorkflowCurrentStep {
    TERMINATION_INITIATED = 'termination_initiated',
    BASELINING_BATCH_PROCESSED = 'baselining_batch_processed'
}

export enum WorkflowType {
    AFTX_3PL_ASSOCIATE_ONBOARDING = 'aftx_3pl_associate_onboarding',
    AFTX_3PL_BULK_USER_ONBOARDING = 'aftx_3pl_bulk_user_onboarding',
    AFTX_3PL_USER_ADMIN_ONBOARDING  = 'aftx_3pl_user_admin_onboarding',
    USER_TERMINATION = 'user_termination',
    BASELINING_ASSOCIATE = 'baselining_associate',
    BASELINING_USER_ADMIN_PRIMARY = 'baselining_user_admin_primary',
    BASELINING_USER_ADMIN_SECONDARY = 'baselining_user_admin_secondary',
    BASELINING_ALL_ASSOCIATE = 'baselining_all_associate',
    BASELINING_ALL_USER_ADMIN_PRIMARY = 'baselining_all_user_admin_primary',
    BASELINING_ALL_USER_ADMIN_SECONDARY = 'baselining_all_user_admin_secondary',
    MFA_REGISTRATION = 'mfa_registration',
    BULK_MFA_REGISTRATION = 'bulk_mfa_registration',
    BASELINING_ALL_PERMISSIONS = 'baselining_all_permissions',
    BASELINING_PERMISSION = 'baselining_permission',
}

export enum WorkflowStatus {
    ALL = 'ALL',
    ACTIVE = 'ACTIVE',
    COMPLETED = 'COMPLETED',
    SUCCEEDED = 'SUCCEEDED',
    FAILED = 'FAILED'
}

export enum WorkflowState {
    ACTIVE = 'active',
    SUCCEEDED = 'succeeded',
    FAILED = 'failed'
}

export enum BaseliningUserOperation {
    REMOVE = 'REMOVE',
    KEEP = 'KEEP'
}

export enum BaseliningPermissionOperation {
    NONE = 'NONE',
    BEGINNER = 'BEGINNER',
    INTERMEDIATE = 'INTERMEDIATE',
    EXPERT = 'EXPERT',
    ADMIN = 'ADMIN'
}

export enum BaseliningStatus {
    PENDING = 'PENDING',
    DELEGATED_ORIGIN = 'DELEGATED_ORIGIN',
    DELEGATED_DESTINATION = 'DELEGATED_DESTINATION',
    SUBMITTED = 'SUBMITTED'
}

export enum PermissionBaseliningSignalType {
    SUBMISSION = 'submission',
    DELEGATION = 'delegation'
}

export enum Action {
    VIEW_AS = "ViewAs",
    REFRESH = "Refresh"
}

export enum OnboardType {
    SINGLE = 'single',
    BULK = 'bulk'
}

export enum NationalIdCountry {
    ABW = 'ABW',
    AFG = 'AFG',
    AGO = 'AGO',
    AIA = 'AIA',
    ALA = 'ALA',
    ALB = 'ALB',
    AND = 'AND',
    ARE = 'ARE',
    ARG = 'ARG',
    ARM = 'ARM',
    ASM = 'ASM',
    ATG = 'ATG',
    AUS = 'AUS',
    AUT = 'AUT',
    AZE = 'AZE',
    BDI = 'BDI',
    BEL = 'BEL',
    BEN = 'BEN',
    BES = 'BES',
    BFA = 'BFA',
    BGD = 'BGD',
    BGR = 'BGR',
    BHR = 'BHR',
    BHS = 'BHS',
    BIH = 'BIH',
    BLM = 'BLM',
    BLR = 'BLR',
    BLZ = 'BLZ',
    BMU = 'BMU',
    BOL = 'BOL',
    BRA = 'BRA',
    BRB = 'BRB',
    BRN = 'BRN',
    BTN = 'BTN',
    BWA = 'BWA',
    CAF = 'CAF',
    CAN = 'CAN',
    CCK = 'CCK',
    CHE = 'CHE',
    CHL = 'CHL',
    CIV = 'CIV',
    CMR = 'CMR',
    COD = 'COD',
    COG = 'COG',
    COK = 'COK',
    COM = 'COM',
    CPV = 'CPV',
    CUB = 'CUB',
    CUW = 'CUW',
    CXR = 'CXR',
    CYM = 'CYM',
    CYP = 'CYP',
    CZE = 'CZE',
    DEU = 'DEU',
    DJI = 'DJI',
    DMA = 'DMA',
    DNK = 'DNK',
    DOM = 'DOM',
    DZA = 'DZA',
    ECU = 'ECU',
    EGY = 'EGY',
    ERI = 'ERI',
    ESH = 'ESH',
    ESP = 'ESP',
    EST = 'EST',
    ETH = 'ETH',
    FIN = 'FIN',
    FJI = 'FJI',
    FLK = 'FLK',
    FRA = 'FRA',
    FRO = 'FRO',
    FSM = 'FSM',
    GAB = 'GAB',
    GBR = 'GBR',
    GEO = 'GEO',
    GGY = 'GGY',
    GHA = 'GHA',
    GIB = 'GIB',
    GIN = 'GIN',
    GLP = 'GLP',
    GMB = 'GMB',
    GNB = 'GNB',
    GNQ = 'GNQ',
    GRC = 'GRC',
    GRD = 'GRD',
    GRL = 'GRL',
    GTM = 'GTM',
    GUF = 'GUF',
    GUM = 'GUM',
    GUY = 'GUY',
    HND = 'HND',
    HRV = 'HRV',
    HTI = 'HTI',
    HUN = 'HUN',
    IDN = 'IDN',
    IMN = 'IMN',
    IND = 'IND',
    IOT = 'IOT',
    IRL = 'IRL',
    IRN = 'IRN',
    IRQ = 'IRQ',
    ISL = 'ISL',
    ISR = 'ISR',
    ITA = 'ITA',
    JAM = 'JAM',
    JEY = 'JEY',
    JOR = 'JOR',
    KAZ = 'KAZ',
    KEN = 'KEN',
    KGZ = 'KGZ',
    KHM = 'KHM',
    KIR = 'KIR',
    KNA = 'KNA',
    KOR = 'KOR',
    KWT = 'KWT',
    LAO = 'LAO',
    LBN = 'LBN',
    LBR = 'LBR',
    LBY = 'LBY',
    LCA = 'LCA',
    LIE = 'LIE',
    LKA = 'LKA',
    LSO = 'LSO',
    LTU = 'LTU',
    LUX = 'LUX',
    LVA = 'LVA',
    MAC = 'MAC',
    MAF = 'MAF',
    MAR = 'MAR',
    MCO = 'MCO',
    MDA = 'MDA',
    MDG = 'MDG',
    MDV = 'MDV',
    MEX = 'MEX',
    MHL = 'MHL',
    MKD = 'MKD',
    MLI = 'MLI',
    MLT = 'MLT',
    MMR = 'MMR',
    MNE = 'MNE',
    MNG = 'MNG',
    MNP = 'MNP',
    MOZ = 'MOZ',
    MRT = 'MRT',
    MSR = 'MSR',
    MTQ = 'MTQ',
    MUS = 'MUS',
    MWI = 'MWI',
    MYS = 'MYS',
    MYT = 'MYT',
    NAM = 'NAM',
    NCL = 'NCL',
    NER = 'NER',
    NFK = 'NFK',
    NGA = 'NGA',
    NIC = 'NIC',
    NIU = 'NIU',
    NLD = 'NLD',
    NOR = 'NOR',
    NPL = 'NPL',
    NRU = 'NRU',
    NZL = 'NZL',
    OMN = 'OMN',
    PAK = 'PAK',
    PAN = 'PAN',
    PCN = 'PCN',
    PER = 'PER',
    PHL = 'PHL',
    PLW = 'PLW',
    PNG = 'PNG',
    POL = 'POL',
    PRI = 'PRI',
    PRK = 'PRK',
    PRT = 'PRT',
    PRY = 'PRY',
    PSE = 'PSE',
    PYF = 'PYF',
    QAT = 'QAT',
    REU = 'REU',
    ROU = 'ROU',
    RUS = 'RUS',
    RWA = 'RWA',
    SAU = 'SAU',
    SDN = 'SDN',
    SEN = 'SEN',
    SGP = 'SGP',
    SHN = 'SHN',
    SJM = 'SJM',
    SLB = 'SLB',
    SLE = 'SLE',
    SLV = 'SLV',
    SMR = 'SMR',
    SOM = 'SOM',
    SPM = 'SPM',
    SRB = 'SRB',
    SSD = 'SSD',
    STP = 'STP',
    SUR = 'SUR',
    SVK = 'SVK',
    SVN = 'SVN',
    SWE = 'SWE',
    SWZ = 'SWZ',
    SXM = 'SXM',
    SYC = 'SYC',
    SYR = 'SYR',
    TCA = 'TCA',
    TCD = 'TCD',
    TGO = 'TGO',
    THA = 'THA',
    TJK = 'TJK',
    TKL = 'TKL',
    TKM = 'TKM',
    TLS = 'TLS',
    TON = 'TON',
    TTO = 'TTO',
    TUN = 'TUN',
    TUR = 'TUR',
    TUV = 'TUV',
    TZA = 'TZA',
    UGA = 'UGA',
    UKR = 'UKR',
    UMI = 'UMI',
    URY = 'URY',
    USA = 'USA',
    UZB = 'UZB',
    VAT = 'VAT',
    VCT = 'VCT',
    VEN = 'VEN',
    VGB = 'VGB',
    VIR = 'VIR',
    VNM = 'VNM',
    VUT = 'VUT',
    WLF = 'WLF',
    WSM = 'WSM',
    XKX = 'XKX',
    YEM = 'YEM',
    ZAF = 'ZAF',
    ZMB = 'ZMB',
    ZWE = 'ZWE'
}

export enum NationalIdType {
    NATIONAL_ID_TAIL = 'NATIONAL_ID_TAIL'
}

export enum BackgroundCheckVendorName {
    ACCURATE = 'Accurate',
    FIRST_ADVANTAGE = 'First Advantage',
    TRUE_SCREEN = 'True Screen'
}