import {ACTION_OPTIONS, DROPDOWN_MAX_HEIGHT, EMPTY_VALUE} from "src/common/constants";
import { Dropdown } from "@amzn/alchemy-components-react";
import { useTranslation } from "react-i18next";
import { Action } from "src/common/enums";
import React, {useState} from "react";

interface ActionsDropdownProps {
    onViewAsClick: () => void;
}

export const ActionsDropdown: React.FC<ActionsDropdownProps> = ({ onViewAsClick }) => {
    const { t } = useTranslation();
    const DEFAULT_OPTION = { label: t('actions'), value: EMPTY_VALUE };
    const [selectedValue, setSelectedValue] = useState(DEFAULT_OPTION.value);

    const actionOptions = [
        DEFAULT_OPTION,
        ...ACTION_OPTIONS.map((option) => ({
            label: t(option.label),
            value: option.value
        }))
    ];

    const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const value = e.target.value;
        setSelectedValue(value);
        console.log('Dropdown changed to:', value); // Debug log
        if (value === Action.VIEW_AS) {
            console.log('Triggering view as click'); // Debug log
            onViewAsClick();
            setSelectedValue(DEFAULT_OPTION.value);
        }
    };

    return (
        <Dropdown
            id="actions-dropdown"
            label={t("select-an-action")}
            options={actionOptions}
            value={selectedValue}
            maxHeight={DROPDOWN_MAX_HEIGHT}
            onChange={handleChange}
        />
    );
};
